import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import users from '@/store/modules/users'
import maintenances from '@/store/modules/maintenances'
import functions from '@/store/modules/functions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { auth, users, maintenances, functions },
  state: {
    isProcessing: true,
    isSubmitting: false,
    isAuthProcessing: true,
    redirectPath: null,
    telop: {
      show: false,
      msg: '',
      type: ''
    },
    collections: []
  },
  getters: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 処理中かどうか
     */
    isProcessing: state => state.isProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} フォーム等の送信中かどうか
     */
    isSubmitting: state => state.isSubmitting,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} Auth処理中かどうか
     */
    isAuthProcessing: state => state.isAuthProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURL
     */
    redirectPath: state => state.redirectPath,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} テロップの情報
     */
    telop: state => state.telop,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String[]} firestoreのコレクションの一覧
     */
    collections: state => state.collections
  },
  mutations: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isAuthProcessing Auth処理中かどうか
     */
    setAuthProcessing: (state, isAuthProcessing) => {
      state.isAuthProcessing = isAuthProcessing
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isProcessing 処理中かどうか
     */
    setProcessing: (state, isProcessing) => {
      state.isProcessing = isProcessing
      if (state.isSubmitting) state.isSubmitting = false
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isSubmitting フォーム等の送信中かどうか
     */
    setSubmitting: (state, isSubmitting) => {
      state.isSubmitting = isSubmitting
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} path アクセスされたURL
     */
    setRedirectURL: (state, path) => {
      state.redirectPath = path
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} telop テロップの情報
     * @param {Boolean} telop.show テロップ表示の有無
     * @param {String} telop.msg テロップの内容
     * @param {String} telop.type テロップの色 success, warning, error
     */
    setTelop: (state, telop) => {
      state.telop = telop
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String[]} collections firestoreのコレクション一覧
     */
    setCollections: (state, collections) => {
      state.collections = collections
    }
  },
  actions: {}
})
