import { firestore } from '@/firebase'
import router from '@/router'

const state = {
  // 全ユーザーのオブジェクト一覧
  // { uid: {}, uid: {}, ... }
  users: {}
}

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  user: state => uid => state.users[uid] ? state.users[uid] : null
}

const mutations = {
  /**
   * ユーザー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.user ユーザーのオブジェクト
   */
  setUser: (state, payload) => {
    state.users[payload.uid] = payload.user
  }
}

const actions = {
  /**
   * ユーザー情報を取得
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  getUser ({ commit }, uid) {
    return new Promise(resolve => {
      firestore
        .collection('users')
        .doc(uid)
        .get()
        .then(doc => {
          const user = doc.exists ? doc.data() : null
          if (user) commit('setUser', { uid: uid, user: user })
          resolve(user)
        })
        .catch(() => {
          router.push({ name: 'error' })
        })
    })
  },
  /**
   * 管理者一覧の取得
   * @return {Object} 管理者一覧 { uid: {}, uid: {}, ... }
   */
  getAdmins ({ commit, rootGetters }) {
    return new Promise(resolve => {
      firestore
        .collection('users')
        .where('authority', '==', 'admin')
        .get()
        .then(snapshot => {
          const admins = {}

          // 自分自身は除くため自分のuidを取得しておく
          const uid = rootGetters['auth/uid']

          // 管理者情報を返り値に合わせてフォーマット
          snapshot.forEach(doc => {
            if (uid !== doc.id) admins[doc.id] = doc.data()
          })
          resolve(admins)
        })
        .catch(() => {
          router.push({ name: 'error' })
        })
    })
  },
  /**
  * ユーザー情報の更新
  * @param {Object} payload 引数
  * @param {String} payload.uid ユーザーID
  * @param {Object} payload.params ユーザー情報
  * @return {void}
  */
  updateUser ({ commit }, payload) {
    return new Promise(resolve => {
      firestore
        .collection('users')
        .doc(payload.uid)
        .update(payload.params)
        .then(() => {
          resolve()
        })
        .catch(() => {
          router.push({ name: 'error' })
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
