import { firestore, timestamp } from '@/firebase'
import router from '@/router'

const state = {
  // 最新のメンテナンス情報
  maintenance: null
}

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新のメンテナンス情報
   */
  maintenance: state => state.maintenance
}

const mutations = {
  /**
   * メンテナンス情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} maintenance メンテナンス情報のオブジェクト
   */
  setMaintenance: (state, maintenance) => {
    state.maintenance = maintenance
  }
}
const actions = {
  /**
   * メンテナンス情報の作成
   * @param {Object} payload 引数のメンテナンス情報
   * @return {void}
   */
  createMaintenance ({ commit }, payload) {
    return new Promise(resolve => {
      firestore
        .collection('maintenances')
        .add(payload)
        .then(doc => {
          // そのままだとDate型でstateに格納されてしまうのでfirestoreのTimestamp型に変換する
          payload.createdAt = timestamp.fromDate(payload.createdAt)
          commit('setMaintenance', Object.assign({ mid: doc.id }, payload ))
          resolve()
        })
        .catch(() => {
          router.push({ name: 'error' })
        })
    })
  },
  /**
   * メンテナンス情報の取得
   * @return {void}
   */
  getMaintenance ({ commit }) {
    return new Promise(resolve => {
      firestore
        .collection('maintenances')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => commit('setMaintenance', Object.assign({ mid: doc.id }, doc.data())))
          resolve()
        })
        .catch(() => {
          router.push({ name: 'error' })
        })
    })
  },
  /**
   * メンテナンス情報の更新
   * @param {Object} payload 引数の情報
   * @param {String} payload.mid メンテナンスID
   * @param {Object} payload.params メンテナンス情報
   * @return {void}
   */
  updateMaintenance ({ commit }, payload) {
    return new Promise(resolve => {
      firestore
        .collection('maintenances')
        .doc(payload.mid)
        .update(payload.params)
        .then(() => {
          commit('setMaintenance', Object.assign({ mid: payload.mid }, payload.params ))
          resolve()
        })
        .catch(() => {
          router.push({ name: 'error' })
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
