import Vue from 'vue'
import VueSanitize from 'vue-sanitize'

import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

Vue.config.productionTip = false

/**
 * setting of vue sanitize
 * @see https://github.com/daichirata/vue-sanitize
 */
Vue.use(VueSanitize)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
