<template>
  <header class="header" v-if="isDisplay">
    <v-btn text class="header__btn" v-if="type.includes('back')" @click="back()">
      <v-icon class="header__btn__icon">arrow_back</v-icon>
    </v-btn>
    <v-img class="header__logo" v-if="type.includes('logo')" :src="require('@/assets/img/logo.svg')" contain />
    <p class="header__title" v-if="type.includes('title')">{{ title }}</p>
  </header>
</template>

<script>
// ヘッダーを表示させないページ名
const NOT_DISPLAY = [
  'error',
  'notfound'
]

// ヘッダーの詳細
// type: back, logo, title
const HEADER_DETAIL = {
  login: {
    type: ['logo']
  },
  home: {
    type: ['logo']
  },
  admin: {
    type: ['back', 'title'],
    title: '管理者権限'
  },
  admin_add: {
    type: ['back', 'title'],
    title: '管理者権限の追加'
  },
  admin_delete: {
    type: ['back', 'title'],
    title: '管理者権限の削除'
  },
  mail: {
    type: ['back', 'title'],
    title: 'メール送信'
  },
  mail_all: {
    type: ['back', 'title'],
    title: '全ユーザーにメール送信'
  },
  maintenance: {
    type: ['back', 'title'],
    title: 'メンテナンス'
  },
  maintenance_start: {
    type: ['back', 'title'],
    title: 'メンテナンスの開始'
  },
  maintenance_end: {
    type: ['back', 'title'],
    title: 'メンテナンスの終了'
  },
  develop: {
    type: ['back', 'title'],
    title: '開発環境用の処理'
  },
  develop_reset_collections: {
    type: ['back', 'title'],
    title: 'コレクションのリセット'
  },
  setting: {
    type: ['back', 'title'],
    title: '設定'
  }
}

export default {
  computed: {
    /**
     * @return {Boolean} ヘッダーを表示させるかどうか
     */
    isDisplay () {
      return !NOT_DISPLAY.includes(this.$route.name)
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      return this.pageName && HEADER_DETAIL[this.pageName].title ? HEADER_DETAIL[this.pageName].title : ''
    },
    /**
     * @return {String[]} ヘッダーのタイプ
     */
    type () {
      return this.pageName && HEADER_DETAIL[this.pageName].type ? HEADER_DETAIL[this.pageName].type : []
    }
  },
  methods: {
    /**
     * 前のページに戻る
     * 直リンクなどで前のページが存在しない場合はホームへ遷移させる
     */
    back () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.header {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: $header_zindex;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: $max_width;
  height: $header_height;
  text-align: center;
  background-color: $white_color;
  transform: translate(-50%, 0);
  &__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: 20px;
      padding: 0;
      color: $black_color;
    }
    &__icon {
      width: 20px;
      height: 20px;
      &.v-icon {
        font-size: 2rem;
      }
    }
  }
  &__logo {
    width: 120px;
    max-height: calc(#{$header_height} - 10px);
    margin: 0 auto;
  }
  &__title {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}
</style>
