<template>
  <div class="home" v-if="!isProcessing">
    <div class="home__menu" v-for="item in items" :key="item.title">
      <home-menu :item="item" />
    </div>
  </div>
</template>

<script>
import HomeMenu from '@/components/common/menu.vue'

export default {
  name: 'home',
  components: { HomeMenu },
  data () {
    return {
      items: [
        { title: '管理者権限', content: '管理者ページのアクセス権限に関する操作ができます。', to: 'admin' },
        { title: 'メール送信', content: 'ユーザーにメールを送信します', to: 'mail' },
        { title: 'メンテナンス', content: 'メンテナンスの開始・終了ができます。', to: 'maintenance' },
        { title: '設定', content: 'ログアウトができる', to: 'setting' }
      ]
    }
  },
  mounted () {
    // 本番環境以外なら開発環境用の画面を表示する
    if (!this.isProduction) {
      this.items.push({
        title: '開発環境用', content: 'DBのリセットを行う画面', to: 'develop'
      })
    }
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 本番環境かどうか
     */
    isProduction () {
      return process.env.NODE_ENV === 'production'
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.home {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_width $padding_width;
  margin: $header_height auto 0 auto;
}
</style>
