import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

import store from '@/store'
import { firebase } from '@/firebase'

/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/admin',
      name: 'admin',
      component: loadComponent('Admin.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/add',
      name: 'admin_add',
      component: loadComponent('AdminAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/admin/delete',
      name: 'admin_delete',
      component: loadComponent('AdminDelete.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/mail',
      name: 'mail',
      component: loadComponent('Mail.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/mail/all',
      name: 'mail_all',
      component: loadComponent('MailAll.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/setting',
      name: 'setting',
      component: loadComponent('Setting.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: loadComponent('Login.vue')
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: loadComponent('Maintenance.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/maintenance/start',
      name: 'maintenance_start',
      component: loadComponent('MaintenanceStart.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/maintenance/end',
      name: 'maintenance_end',
      component: loadComponent('MaintenanceEnd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/develop',
      name: 'develop',
      component: loadComponent('Develop.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/develop/resetCollections',
      name: 'develop_reset_collections',
      component: loadComponent('DevelopResetCollections.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('NotFound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '**',
      redirect: { name: 'notfound' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // 別画面に遷移する時、processingを表示状態にする
  if (to.name !== from.name) store.commit('setProcessing', true)

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const uid = auth ? auth.uid : null
  const isAuthProcessing = store.getters.isAuthProcessing

  // 認証、ユーザー取得状況に応じてページ遷移
  // 初回アクセス時、onAuthの処理が終わる前にrouterが動くため、初回処理が終わるまで遷移をループさせる
  if (isAuthProcessing || autoTransition) {
    // 初回アクセス時は無限ループ
    // 無条件で遷移されるページの場合は、そのまま遷移
    if (autoTransition) next()
  } else if (!uid) {
    // 未ログインの場合
    requiresAuth ? next({ name: 'login' }) : next()
  } else {
    // ログイン済みの場合
    requiresAuth ? next() : next({ name: 'home' })
  }
})

export default router
