import { firebase } from '@/firebase'
import router from '@/router'

// functionsがあるリージョン
const REGION = 'asia-northeast1'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * メールアドレスからユーザー情報を取得する
   * @param {String} email メールアドレス
   * @return {Object} { email: メールアドレス, uid: ユーザーID, user: ユーザー情報 }
   */
  getUserByEmail ({ commit }, email) {
    return new Promise(resolve => {
      const get = firebase.functions(REGION).httpsCallable('getUserByEmail')
      get({ email: email }).then(results => {
        return resolve(results.data)
      }).catch(() => {
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * メンテナンス用のrulesに変更してDBアクセスを停止
   * @return {void}
   */
  setMaintenanceRules ({ commit }) {
    return new Promise(resolve => {
      const setMaintenanceRules = firebase.functions(REGION).httpsCallable('setMaintenanceRules')
      setMaintenanceRules().then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * メールを送信
   * @param {String} payload メール情報
   * @param {String} payload.subject メールのタイトル
   * @param {String} payload.content メールの本文（改行コード含む）
   * @return {Void}
   */
  sendEmail ({ commit }, payload) {
    return new Promise(resolve => {
      const send = firebase.functions(REGION).httpsCallable('sendEmail')
      send({
        subject: payload.subject,
        content: payload.content
      }).then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * 指定ユーザーにメールを一斉送信
   * @param {String} payload メール情報
   * @param {String} payload.subject メールのタイトル
   * @param {String} payload.content メールの本文（改行コード含む）
   * @param {String} payload.to 送信先 [ all: auth認証がある全員 / users: auth認証かつusersにデータがある全員 ]
   * @return {Void}
   */
  sendEmailAll ({ commit }, payload) {
    return new Promise(resolve => {
      const send = firebase.functions(REGION).httpsCallable('sendEmailAll')
      send({
        subject: payload.subject,
        content: payload.content,
        to: payload.to
      }).then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * テスト環境用のコレクションリセット処理
   * @param {String[]} collections リセットするコレクション名の一覧
   * @return {null} 返戻値無し
   */
  resetCollections ({ commit }, collections) {
    return new Promise(resolve => {
      const resetCollections = firebase.functions(REGION).httpsCallable('resetCollections')
      resetCollections(collections).then(() => {
        return resolve()
      }).catch(() => {
        router.push({ name: 'error' })
      })
    })
  },
  /**
   * テスト環境用のコレクション名一覧取得処理
   * @return {String[]} 返戻値無し
   */
  getAllCollections ({ commit }) {
    return new Promise(resolve => {
      const getAllCollections = firebase.functions(REGION).httpsCallable('getAllCollections')
      getAllCollections().then(result => {
        commit('setCollections', result.data, { root: true })
        return resolve()
      }).catch(() => {
        router.push({ name: 'error' })
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
